/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
import React, { useEffect, useContext } from "react";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import styled from "@emotion/styled";

// Components
import { css } from "@emotion/core";
import Div100vh from "react-div-100vh";
import SwiperPagination from "../Animations/SwiperPagination";
import { slideUp, slideDown } from "../../utilities/animations";
import { useStateRef } from "../../utilities/hooks";

// Components
import { ContextEntryUI } from "../../contexts/context-entry-ui";

const Swiper = styled(Div100vh)`
  width: 100vw;
  display: flex;
  flex-wrap: wrap;
`;

const Slide = styled(Div100vh)`
  position: fixed;
  width: 100vw;
  top: 100vh;
  overflow: hidden;
  &:nth-of-type(1) {
    top: 0;
  }
`;

const HomeSlides = ({ slides, slideImage = <></> }) => {
  const { entryClosedRef, slidesAbove, readyToSlideDown } = useContext(
    ContextEntryUI
  );
  const [currentIndex, setCurrentIndex, currentIndexRef] = useStateRef(0);
  const duration = 2.5;
  const slideCount = slides.length - 1;
  const ease = "Power1.easeInOut";

  // Slide to next index
  const slideNext = newIndex => {
    if (currentIndexRef.current >= slideCount) return;
    slideDown(duration, ease, currentIndexRef.current, newIndex);
    setCurrentIndex(currentIndexRef.current + 1);
  };

  // Slide to previous index
  const slidePrev = newIndex => {
    if (currentIndexRef.current === 0) return;
    slideUp(duration, ease, currentIndexRef.current, newIndex);
    setCurrentIndex(currentIndexRef.current - 1);
  };

  // // eslint-disable-next-line consistent-return
  // const goToSlide = newIndex => {
  //   if (newIndex < 0 || newIndex > slideCount) return;
  //   if (newIndex > currentIndexRef.current) {
  //     slideUp(duration, ease, currentIndexRef.current, newIndex);
  //   } else {
  //     slideDown(duration, ease, currentIndexRef.current, newIndex);
  //   }
  //   setCurrentIndex(newIndex);
  // };

  // Rachel's fun code
  let currentlySliding = false;

  // eslint-disable-next-line consistent-return
  const slideScroll = e => {
    if (entryClosedRef.current) return;
    currentlySliding = true;
    setTimeout(() => {
      currentlySliding = false;
    }, duration * 1000);
    e.preventDefault();
    if (e.deltaY > 0) {
      if (currentIndexRef.current >= slideCount) return;
      slidesAbove.current = true;
      slideNext(currentIndexRef.current + 1);
    }

    if (e.deltaY < 0) {
      if (currentIndexRef.current === 0) {
        slidesAbove.current = false;
        return;
      }
      slidePrev(currentIndexRef.current - 1);
    }
  };

  // const slideToHome = () => {
  //   goToSlide(0);
  // };

  // const addScrollEventToEscape = () => {
  //   document.querySelector(".logo-main").addEventListener("click", slideToHome);
  // };

  // const removeScrollEventOnEscape = () => {
  //   document
  //     .querySelector(".logo-main")
  //     .removeEventListener("click", slideToHome);
  // };

  let prevDeltaY = 0;

  const scrollEvent = e => {
    if (readyToSlideDown.current) {
      if (
        Math.abs(e.deltaY) - Math.abs(prevDeltaY) >= 0 &&
        !currentlySliding &&
        Math.abs(e.deltaY) > 1
      ) {
        slideScroll(e);
      }
      prevDeltaY = e.deltaY;
    }
  };

  let start = 0;
  const startScroll = e => {
    start = e.touches[0].clientY;
  };
  const moveScroll = e => {
    const moved = e.changedTouches[0].clientY;
    if (readyToSlideDown.current && !currentlySliding)
      slideScroll({ deltaY: start > moved ? 1 : -1, preventDefault: () => {} });
  };

  useEffect(() => {
    // Disable scrolling for this screen
    disableBodyScroll();
    // Add scroll event to starting slide
    document.addEventListener("wheel", scrollEvent);
    window.addEventListener("touchstart", startScroll);
    window.addEventListener("touchmove", moveScroll);
    // Add click scroll to escape button
    // addScrollEventToEscape();

    return () => {
      if (typeof window !== "undefined") {
        document.removeEventListener("wheel", scrollEvent);
        window.removeEventListener("touchstart", startScroll);
        window.removeEventListener("touchmove", moveScroll);
      }
      // removeScrollEventOnEscape();
      enableBodyScroll();
    };
  }, []);

  const keys = [1, 2, 3, 4, 5, 6];

  return (
    <>
      <Swiper className="home-slides">
        {slides.map((slide, i) => (
          <Slide key={keys[i]} className={`home-slide-${i}`}>
            {slide}
          </Slide>
        ))}
      </Swiper>
      <SwiperPagination
        progress={(currentIndex + 1) / (slideCount + 1)}
        duration={duration}
        ease={ease}
      />
      <Div100vh
        id="slide-transition"
        css={css`
          position: absolute;
          z-index: 1000;
          width: 100vw;
          top: -100vh;
          left: 0px;
          overflow: hidden;
          background: transparent;
        `}
      >
        {slideImage}
      </Div100vh>
    </>
  );
};

export default HomeSlides;
